<template>
  <div class="catering">
    <img src="../../assets/product/catering/1.png" class="headerimg" />

    <div class="case">
      <div class="title">基于小程序，打造全场景一体化数字化餐饮解决方案</div>
      <div class="caseimg">
        <img src="../../assets/product/catering/2.png" alt="" />
      </div>
    </div>

    <div class="backcolor">
      <div class="caselist">
        <div class="casecard">
          <div class="casebac">
            <div class="casebactitle">会员外卖</div>
            <div class="casebacinfo">
              通过自建外卖平台，主导自有外卖业务，打通点餐-支付-出票-配送全链路
            </div>
          </div>
          <div class="casebac casebaclef">
            <div class="casebactitle">会员商城</div>
            <div class="casebacinfo">
              通过自建商城，实现半成品或衍生品售卖，增加餐企销售渠道
            </div>
          </div>
          <div class="casebac">
            <div class="casebactitle">堂食会员</div>
            <div class="casebacinfo">
              点餐、支付、营销等多维度聚合会员权益，提升堂食用餐体验
            </div>
          </div>
        </div>
        <div class="caseinfo">
          <div class="casetitle">智慧餐厅·以三店一体</div>
          <div class="subheading">构建餐企私域</div>
          <div class="casetext">
            为餐企布局“堂食+外卖+商城”三店一体化的餐饮经营模式，构建餐企私域流量池
          </div>
        </div>
      </div>
    </div>

    <div class="caselist">
      <div class="caseinfo">
        <div class="casetitle">邦伲德会员·更专业的</div>
        <div class="subheading">顾问服务</div>
        <div class="casetext">
          结合邦伲德在会员领域14年的经验，提供更丰富的三店一体化解决方案和更专业的运营服务
        </div>
      </div>
      <div class="casecard">
        <div class="management">
          <div class="managementtitle">会员管理</div>
          <div class="managementlist">
            <div class="managementcard">
              <div class="managementimg">
                <img src="../../assets/product/catering/3.png" alt="" />
              </div>
              <div class="managementinfo">会员方法论</div>
              <div class="managementtext">体系、方法、流程</div>
            </div>
            <div class="managementcard">
              <div class="managementimg">
                <img src="../../assets/product/catering/4.png" alt="" />
              </div>
              <div class="managementinfo">连锁餐饮CRM系统</div>
              <div class="managementtext">工具、系统、软件</div>
            </div>
            <div class="managementcard">
              <div class="managementimg">
                <img src="../../assets/product/catering/5.png" alt="" />
              </div>
              <div class="managementinfo">专业顾问服务</div>
              <div class="managementtext">运营、分析、服务</div>
            </div>
          </div>
        </div>

        <div class="description">
          <div class="descriptionlist">
            <div class="descriptioncard">
              <div class="descriptiontitle">会员方案定制</div>
              <div class="descriptioninfo">
                根据餐厅经营数据及客群特征，设定整体会员运营方案并持续跟踪与优化
              </div>
            </div>
            <div class="descriptioncard">
              <div class="descriptiontitle">数据营销</div>
              <div class="descriptioninfo">
                根据大数据与餐饮经营现状，结合行业实践，提供营销计划、设计与执行
              </div>
            </div>
            <div class="descriptioncard">
              <div class="descriptiontitle">数据解读</div>
              <div class="descriptioninfo">
                制作及解读数据报表、分析数据，提供建议
              </div>
            </div>
          </div>
          <div class="descriptiolist">
            <div class="descriptioncard">
              <div class="descriptiontitle">经营分析建议</div>
              <div class="descriptioninfo">
                多年餐饮从业经验及资源积累，结合大数据罗盘，为餐厅提供相应经营建议
              </div>
            </div>
            <div class="descriptioncard">
              <div class="descriptiontitle">执行优化</div>
              <div class="descriptioninfo">
                自行设定及目标拆解，培训辅助、督导，确保落地执行
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="backcolor">
      <div class="caselist">
        <div class="casecard">
          <div class="serve">
            <div class="serveimg">
              <img src="../../assets/product/catering/6.png" alt="" />
            </div>
            <div class="serveinfo">
              <div class="servetitle">收银服务</div>
              <div class="servetext">
                下单、核销、支付全覆盖，提升效率和体验
              </div>
            </div>
          </div>
          <div class="serve">
            <div class="serveimg">
              <img src="../../assets/product/catering/7.png" alt="" />
            </div>
            <div class="serveinfo">
              <div class="servetitle">智能推荐</div>
              <div class="servetext">
                历史消费记录、关联菜品推荐、菜品销量排行推荐
              </div>
            </div>
          </div>
          <div class="serve">
            <div class="serveimg">
              <img src="../../assets/product/catering/8.png" alt="" />
            </div>
            <div class="serveinfo">
              <div class="servetitle">厨房KDS</div>
              <div class="servetext">
                信息传达更清晰，传菜不丢单；并单操作，减少错误，提升效率
              </div>
            </div>
          </div>
          <div class="serve">
            <div class="serveimg">
              <img src="../../assets/product/catering/9.png" alt="" />
            </div>
            <div class="serveinfo">
              <div class="servetitle">场景营销</div>
              <div class="servetext">
                预定、排队、点餐、打包外带、外卖、多场景聚合会员权益
              </div>
            </div>
          </div>
          <div class="serve">
            <div class="serveimg">
              <img src="../../assets/product/catering/10.png" alt="" />
            </div>
            <div class="serveinfo">
              <div class="servetitle">数据管理</div>
              <div class="servetext">
                会员数据、财务数据、经营数据灵活查看，诊断反馈门店经营情况
              </div>
            </div>
          </div>
          <div class="serve">
            <div class="serveimg">
              <img src="../../assets/product/catering/11.png" alt="" />
            </div>
            <div class="serveinfo">
              <div class="servetitle">精准营销</div>
              <div class="servetext">分会员营销，分场景营销，分业态营销</div>
            </div>
          </div>
        </div>
        <div class="caseinfo">
          <div class="casetitle">雅座收银·全场景会员一体化</div>
          <div class="casetext">
            为餐企布局“堂食+外卖+商城”三店一体化的餐饮经营模式，构建餐企私域流量池
          </div>
        </div>
      </div>
    </div>

    <div class="caselist">
      <div class="caseinfo">
        <div class="casetitle">省成本·就从采购开始</div>
        <div class="casetext">
          从采购、用料、加工三个方面，帮助餐饮企业提升效率，增加利润
        </div>
      </div>
      <div class="casecard">
        <div class="casecardimg">
          <img src="../../assets/product/catering/12.png" alt="" />
        </div>
      </div>
    </div>

    <div class="backcolor">
      <div class="caselist">
        <div class="casecard">
          <img src="../../assets/product/catering/18.png" alt="" />
        </div>
        <div class="caseinfo">
          <div class="casetitle">商有外卖·提供外卖营销管理平台</div>
          <div class="casetext">
            从点餐到配送，覆盖外卖业务全流程，为餐饮商家提供完整的外卖营销管理平台
          </div>
        </div>
      </div>
    </div>

    <div class="system">
      <div class="title">360°多方位服务体系</div>
      <div class="systemimg">
        <img src="../../assets/product/catering/17.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.system {
  margin: 140px 0px;
  .systemimg {
    text-align: center;
    margin-top: 60px;
    img {
      width: 1400px;
    }
  }
}

.caselist {
  margin: auto;
  display: flex;
  width: 80%;
  justify-content: space-between;
  padding: 90px 0px;
  align-items: center;
  .casecard {
    .casecardimg {
      img {
        width: 580px;
      }
    }

    .serve {
      width: 440px;
      height: 104px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 24px;
      background: #ffffff;
      display: flex;
      align-items: center;
      .serveimg {
        img {
          width: 60px;
        }
      }
      .serveinfo {
        margin-left: 20px;
        .servetitle {
          font-size: 24px;
          font-weight: 500;
          color: #333333;
          line-height: 36px;
        }
        .servetext {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
        }
      }
    }

    .description {
      margin-top: 40px;
      width: 693px;
      height: 308px;
      box-sizing: border-box;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 14px 14px 0px 0px;
      padding: 30px 20px;
      // flex-wrap: wrap ;
      .descriptiolist {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .descriptioncard {
          margin: 6px;
        }
      }
      .descriptionlist {
        display: flex;
        justify-content: space-between;
      }
      .descriptioncard {
        width: 210px;
        height: 114px;
        background: #f5f7fa;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 10px;
        .descriptiontitle {
          font-size: 20px;
          font-weight: 500;
          color: #fda736;
          line-height: 30px;
        }
        .descriptioninfo {
          font-size: 14px;
          font-weight: 400;
          color: #616b80;
          line-height: 20px;
          margin-top: 11px;
        }
      }
    }

    .management {
      width: 693px;
      height: 296px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 14px 14px 0px 0px;
      text-align: center;
      box-sizing: border-box;
      .managementtitle {
        width: 695px;
        height: 82px;
        background: linear-gradient(270deg, #fcca7e 0%, #fda533 100%);
        border-radius: 14px 14px 0px 0px;
        line-height: 82px;
        color: #fff;
        font-size: 30px;
        font-weight: 500;
      }

      .managementlist {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        .managementcard {
          .managementimg {
            img {
              width: 60px;
            }
          }
          .managementinfo {
            margin-top: 20px;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            line-height: 36px;
          }
          .managementtext {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #616b80;
            line-height: 20px;
          }
        }
      }
    }

    .casebaclef {
      margin-left: 275px;
    }
    .casebac {
      width: 383px;
      height: 126px;
      background: linear-gradient(90deg, #fda533 0%, #fcca7e 100%);
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;
      color: #fff;
      margin-bottom: 40px;
      .casebactitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
      }
      .casebacinfo {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  .caseinfo {
    font-size: 36px;
    font-weight: 500;
    line-height: 50px;
    .casetext {
      margin-top: 40px;
      font-size: 24px;
      font-weight: 500;
      color: #616b80;
      line-height: 40px;
      width: 600px;
    }
  }
}

.backcolor {
  background: #f5f7fa;
}

.case {
  margin: 140px 0px;
  .caseimg {
    margin-top: 60px;
    text-align: center;
  }
}
</style>
